import * as React from "react"
import Layout from "../components/Layout/Layout"
import styled from "styled-components"
import variables from "../styles/variables"
import breakpoints from "../styles/breakpoints"

// @todo add meta title and meta description to SEO component

const PageContainer = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${variables.pagePaddingDesktop};
  padding-right: ${variables.pagePaddingDesktop};
  margin-top: ${variables.space2xl};
  /* text-align: center; */

  h1 {
    line-height: 1.2;
    font-size: ${variables.textL};
    font-weight: ${variables.fontWeightBold};
  }

  p {
    font-size: ${variables.textS};
    margin-top: ${variables.spaceLG};
  }

  @media (min-width: ${breakpoints.md}) {
    text-align: center;
  }
`

export default function NotFoundPage() {
  return (
    <Layout>
      <PageContainer>
        <h1>Page Not Found</h1>
        <p>Sorry, we couldn't find what you were looking for</p>
      </PageContainer>
    </Layout>
  )
}
